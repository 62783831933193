import React, { useEffect, useState } from 'react'
import Fade from 'react-reveal'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Layout from '../components/Layout'

export const IndexPageTemplate = ({
  title,
  image
}) => {
  const [ chosenImage, setChosenImage ] = useState(null)
  useEffect(() => {
    setChosenImage(image[Math.floor(Math.random() * image.length)])
  }, [])

  return (
    <Fade>
      <div className="cMain">
        <div className="cMain-inner">
          <div className="cMain-image">
            <PreviewCompatibleImage
              imageInfo={{
                image: chosenImage,
                alt: ''
              }}
            />
          </div>
          <div className="cMain-content">
            <h1 className="cMain-title">
              <Link to="/contact" className="cMain-link link">{title}</Link>
            </h1>
          </div>
        </div>
      </div>
    </Fade>
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title,
        image
      }
    }
  }
`
