import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

class PreviewCompatibleImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { imageOrientation: '' }
  }

  handleImageLoaded = (e) => {
    const orientation = e.target.width > e.target.height ? 'landscape' : 'portrait'
    this.setState({ imageOrientation: orientation })
  }
  render() {
    const { alt = '', childImageSharp, image } = this.props.imageInfo
    if (!!image && !!image.childImageSharp) {
      return (
        <Img className={this.state.imageOrientation} fluid={image.childImageSharp.fluid} alt={alt} />
      )
    }

    if (!!childImageSharp) {
      return <Img className={this.state.imageOrientation} fluid={childImageSharp.fluid} alt={alt} />
    }

    if (!!image && typeof image === 'string')
      return <img className={this.state.imageOrientation} src={image} alt={alt} onLoad={this.handleImageLoaded.bind(this)} />

    return null
  }
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
  }).isRequired,
}

export default PreviewCompatibleImage
